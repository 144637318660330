<template>
    <div class="self-start relative">
        <p class="font-bold text-base border-b pb-2 mb-4">
            Påminnelser
        </p>
        <div v-for="reminder in invoice.reminders" class="flex justify-start mb-4" :key="reminder.id">
            <div class="w-10 h-10 flex justify-center items-center text-white mr-3 bg-green-400 rounded-full">
                <i class="fa-regular fa-bell fa-lg text-base" style="color: hsl(0, 0%, 99%);" />
            </div>
            <div class="flex-col">
                <p class="font-light">Påminnelse {{ reminder.counter }}, {{ eventTimeFormater(reminder.createDate) }}</p>
                <p class="text-gray-400 text-xs">Avgift: {{ reminder.reminderFee }} {{ invoice.invoice.currency }} Ränta: {{ reminder.interestAmount }} {{ invoice.invoice.currency }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    props: {
        invoice: {
            type: Object,
            default: () => [],
        },
    },

    created() {
        console.log("🚀 - created - this.reminders:", this.invoice.reminders);
    },

    methods: {
        eventTimeFormater(time) {
            return moment(time).format("YYYY-MM-DD HH:mm");
        },
    },
};
</script>
